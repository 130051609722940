
import {
  IonContent,
  IonPage,
  IonSpinner,
  modalController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonImg,
  },
  props: {
    items: {
      type: Array,
      default: null,
      description: "(default -> 빈배열)",
    },
  },
  setup(props) {
    const router = useRouter();

    const state = reactive({
      stampFillters: [],
      randomNum: Math.floor(Math.random() * 5),
      stamp: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },

      contentLoading: true,
    });

    const onDismissModal = async () => {
      modalController.dismiss();
    };

    const onStampLink = (id: number) => {
      console.log(router);
      /*
      router.push({
        name: "StampId",
        params: {id: id}
      })
      */
    };

    const playSound = async (sound: string) => {
      if (sound) {
        const audio = new Audio(sound);
        audio.play();
      }
    };

    const onSelectStamp = async (item: any) => {
      playSound("./assets/audio/select.mp3");
      modalController.dismiss({
        id: item.id,
      });
    };

    onMounted(() => {
      state.stamp.items = [];
      props.items.forEach((item: any) => {
        if (item.acquisition == 0) {
          state.stampFillters = [
            {
              filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #d4ff6e)",
            },
            {
              filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #7ae4ff)",
            },
            {
              filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffdf61)",
            },
            {
              filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ebb5ff)",
            },
            {
              filter: "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffc48d)",
            },
          ] as any;
        } else {
          state.stampFillters = [];
        }
      });
      props.items.some((item: any) => {
        state.stamp.items.push(item);
      });
      state.stamp.loading = false;
      state.contentLoading = false;
      console.log("onMounted");
    });

    return { state, onDismissModal, onStampLink, onSelectStamp };
  },
});
