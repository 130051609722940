
import {
  IonPage,
  IonContent,
  modalController,
  toastController,
  alertController,
  loadingController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonImg,
  IonTextarea,
} from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, reactive } from "vue";
import Curious from "../../components/curious.vue";
import QRScanner from "../../components/QRScanner.vue";
import FindStampResult from "../../components/FindStampResult.vue";
import { useRouter } from "vue-router";
import { Geolocation } from "@capacitor/geolocation";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { useStore } from "vuex";
import { Analytics } from "../../common/analytics";

/**
 * services
 */
import services from "../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    Curious,
    IonImg,
    IonTextarea,
  },
  //Curious
  setup() {
    const store = useStore();
    const router = useRouter();
    const screenName = "PAGE-RADAR";
    const screenNameResult = "MODAL-RADAR_RESULT";
    const analytics = new Analytics();
    const state = reactive({
      ratings: 100,
      review: "",
      explosionStep: 0,
      acquisitionStep: 0,
      isAcquisition: false,
      isQuestion: false,
      isSearchWait: false,
      possibleStampId: 0,
      stamp: {
        loading: true,
        items: [{}],
        data: {
          id: 0,
        },
        totalItems: 0,
        totalPages: 0,
      },

      geolocation: {
        lat: 0,
        lng: 0,
      },

      timer: window.setTimeout(() => {
        console.log("");
      }, 0),

      tool: 1,
      contentLoading: true,
    });

    const openToast = async (message: string) => {
      const toast = await toastController.create({
        message: message,
        duration: 1000,
      });
      return toast.present();
    };

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      return null;
      // const { role } = await alert.onDidDismiss();
      // console.log("onDidDismiss resolved with role", role);
    };

    const playSound = async (sound: string) => {
      if (sound) {
        const audio = new Audio(sound);
        audio.play();
      }
    };

    const onQuestion = async () => {
      state.isQuestion = !state.isQuestion;
    };

    const findQrStamp = async (id: number) => {
      state.tool = 4;
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "스탬프를 찾는 중입니다.",
      });
      await loading.present();

      await services.stampAcquisitionFindone(id).then(
        (response) => {
          const { data } = response;
          console.log(data);

          if (data.acquisition == 0) {
            state.stamp.items.push(data);
            state.possibleStampId = data.id;
            state.isAcquisition = true;
          } else {
            presentAlert("이미 획득한 스탬프입니다.", async () => {
              window.history.length > 1 ? router.go(-1) : router.push("/");
            });
          }

          loading.dismiss();
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          loading.dismiss();
          openToast(message);
        }
      );
    };

    const onQRScanner = async () => {
      if (window.location.host.indexOf("stamppop") != -1) {
        // 웹
        const modal = await modalController.create({
          component: QRScanner,
        });

        modal.onDidDismiss().then((response) => {
          const { data } = response;
          if (data) {
            if (data.id) {
              // findQrStamp(1101292832);
              findQrStamp(Number(data.id));
            }
          }
        });

        return modal.present();
      } else {
        // 앱
        const data = await BarcodeScanner.scan();
        console.log(`Barcode data: ${data.text}`);
        console.log(`Barcode data: ${data}`);
        console.log(data.text.indexOf("stamppop"));
        if (data) {
          if (data.text.indexOf("stamppop") != -1) {
            const temp = data.text.split("/");
            const id = temp[temp.length - 1];
            console.log(id);
            findQrStamp(Number(id));
          }
        }
      }
    };

    const onClose = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `리뷰 작성중..`,
      });
      if (state.review) {
        const params = {
          stampId: state.stamp.data.id,
          content: state.review,
          score: state.ratings,
        };
        await services.stampReviewAdd(params).then(
          (response) => {
            loading.dismiss();
            state.review = "";
            if (response.status == 200) {
              setTimeout(function () {
                presentAlert("작성됐습니다. 홈으로 이동 할까요?", async () => {
                  state.explosionStep = 0;
                  state.acquisitionStep = 0;
                  state.isAcquisition = false;
                  state.isQuestion = false;
                  state.isSearchWait = false;
                  state.possibleStampId = 0;
                  router.push({ name: "home" });
                });
              }, 100);
            }
          },
          (error) => {
            loading.dismiss();
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            // openToast(message);
          }
        );
      } else {
        router.push({ name: "home" });
        state.explosionStep = 0;
        state.acquisitionStep = 0;
        state.isAcquisition = false;
        state.isQuestion = false;
        state.isSearchWait = false;
        state.possibleStampId = 0;
      }
    };

    const explosionAnimation = async () => {
      if (8 <= state.explosionStep) {
        playSound("./assets/audio/reward.mp3");
        /*
        presentAlert('획득 했습니다.',async () =>{
          window.history.length > 1 ? router.go(-1) : router.push('/')
        });
        */
        state.explosionStep++;
        state.acquisitionStep++;
        state.explosionStep = 0;
        state.possibleStampId = 0;
        state.stamp.items = [];

        analytics.setScreenName(screenNameResult);
      } else {
        state.explosionStep++;
        setTimeout(function () {
          explosionAnimation();
        }, 150);
      }
    };

    const onAcquisition = async () => {
      if (state.acquisitionStep < 2) {
        playSound("./assets/audio/crack.mp3");
        state.acquisitionStep++;
      } else {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "스탬프를 획득 중입니다.",
        });
        await loading.present();

        const params = {
          stampId: state.possibleStampId,
          tool: state.tool, // 1:GPS, 4:QR Code, 6:AR
          lat: state.geolocation.lat,
          lng: state.geolocation.lng,
        };
        services
          .stampAcquisitionAdd(params)
          .then((response) => {
            console.log(response);
            if (response.status == 204) {
              state.stamp.items.some((item: any) => {
                if (state.possibleStampId == item.id) {
                  state.stamp.data = item;
                  return;
                }
              });

              loading.dismiss();
              playSound("./assets/audio/explosion.mp3");
              explosionAnimation();
            } else {
              setTimeout(function () {
                loading.dismiss();
              }, 1000);
            }
          })
          .catch((error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            setTimeout(function () {
              loading.dismiss();
              presentAlert(message, async () => {
                state.acquisitionStep = 0;
                state.possibleStampId = 0;
                state.explosionStep = 0;
                state.isAcquisition = false;
                state.stamp.items = [];
              });
            }, 1000);
          });
      }
    };

    const alertPositionError = async (error: any) => {
      let message = "";
      switch (error.code) {
        case error.PERMISSION_DENIED:
          message = "User denied the request for Geolocation.";
          break;
        case error.POSITION_UNAVAILABLE:
          message = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          message = "The request to get user location timed out.";
          break;
        case error.UNKNOWN_ERROR:
          message = "An unknown error occurred.";
          break;
      }

      presentAlert(message /*'위치정보를 허용해 주세요.'*/, async () => {
        console.log("gps");
      });
    };

    const onFindStampResult = async () => {
      const modal = await modalController.create({
        component: FindStampResult,
        componentProps: {
          items: state.stamp.items,
        },
      });

      modal.onDidDismiss().then((response) => {
        const { data } = response;
        if (data) {
          if (data.id) {
            state.possibleStampId = data.id;
            state.isAcquisition = true;
          }
        }
      });

      return modal.present();
    };

    const onFindStamp = async (position: any) => {
      console.log(position);
      state.geolocation.lat = position.coords.latitude;
      state.geolocation.lng = position.coords.longitude;

      const params = {
        lat: state.geolocation.lat,
        lng: state.geolocation.lng,
      };

      if (0 < params.lat && 0 < params.lng) {
        clearTimeout(state.timer);
        state.timer = setTimeout(async () => {
          if (!state.isSearchWait) {
            state.stamp.loading = true;
            state.isSearchWait = true;
            await services.stampAcquisitionFindAll(params).then(
              (response) => {
                const { data } = response;
                console.log(data);
                state.stamp.items = [];
                data.items.some((item: any) => {
                  const distance = item.distance / 1000;
                  console.log(distance);
                  item.distancekm = distance;

                  if (0 < Number(item.acquisitionPossible)) {
                    // state.possibleStampId = item.id;
                  }
                });

                state.stamp.items = data.items;
                state.stamp.totalItems = data.totalItems;
                state.stamp.totalPages = data.totalPages;
                state.stamp.loading = false;
                state.isSearchWait = false;

                if (0 < state.possibleStampId) {
                  console.log("획득가능");
                }

                onFindStampResult();
              },
              (error) => {
                let message = "";
                try {
                  message = error.response.data.message;
                } catch (e) {
                  message = "";
                  console.log(e);
                }
                console.log(message);

                console.log(message);
                state.stamp.loading = false;
                state.isSearchWait = false;
                openToast(message);
              }
            );
          }
        }, 1000);
      }
    };

    const onSearchStamp = async () => {
      state.tool = 1;

      playSound("./assets/audio/search.mp3");

      const container = document.getElementById("fs_content_ripple");
      const el = document.createElement("div");
      el.classList.add("circle");
      if (container) {
        container.appendChild(el);
        setTimeout(function () {
          container.removeChild(el);
        }, 2500);
      }

      const getGeoLocation = store.getters["getGeoLocation"];
      if (getGeoLocation.lat && getGeoLocation.lng) {
        state.geolocation.lat = getGeoLocation.lat;
        state.geolocation.lng = getGeoLocation.lng;
      }

      if (state.geolocation.lat == 0 && state.geolocation.lat == 0) {
        // get the users current position
        console.log("helloGeolocation 1");

        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 5000,
        })
          .then((response) => {
            console.log(response);
            const latitude = response.coords.latitude;
            const longitude = response.coords.longitude;

            store.commit("geolocation", {
              lat: latitude,
              lng: longitude,
            });

            console.log("helloGeolocation 3");
            onFindStamp({
              coords: {
                latitude: latitude,
                longitude: longitude,
              },
            });
          })
          .catch((error) => {
            presentAlert(
              `위치정보를 불러올 수 없습니다. 잠시 후 다시 시도해 주세요. <br /><br />code:${error.code}<br />message:${error.message}`,
              async () => {
                console.log("log");
              }
            );
          });
      } else {
        onFindStamp({
          coords: {
            latitude: state.geolocation.lat,
            longitude: state.geolocation.lng,
          },
        });
      }
    };

    const onStampResultClose = async () => {
      state.explosionStep = 0;
      state.possibleStampId = 0;
      state.stamp.items = [];
    };

    const onStampLink = (id: number) => {
      router.push({
        name: "StampId",
        params: { id: id },
      });
    };

    // onUnmounted
    onUnmounted(() => {
      state.review = "";
      clearTimeout(state.timer);
    });

    onMounted(() => {
      state.review = "";
      state.ratings = 100;
      state.stamp.items = [];
      state.contentLoading = false;
      analytics.setScreenName(screenName);
      analytics.setScreenName(screenNameResult);
    });

    return {
      state,
      onQRScanner,
      onQuestion,
      onSearchStamp,
      onAcquisition,
      onClose,
      onStampResultClose,
      onStampLink,
    };
  },
});
