<template>
  <div class="curious">
    <img
      src="@/assets/img/smile.png"
      alt="스탬프"
      class="stamp_img_02"
    >
    <div class="guide_01">
      <img
        src="@/assets/img/click.png"
        alt="클릭"
        class="hand_img"
      >
      <p class="curious_guide_text">
        내 주변에 있는
      </p>
      <p class="curious_guide_text">
        증강현실 스탬프를 찾아보세요!
      </p>
    </div>
    <div class="guide_02">
      <p class="curious_guide_text">
        QR코드로
      </p>
      <p class="curious_guide_text">
        스탬프로 획득하려면
      </p>
      <p class="curious_guide_text">
        터치!
      </p>
      <p class="curious_guide_text guide_bar">
        <img
          src="@/assets/img/arrow.png"
          alt="터치"
          class="arrow_img"
        >
      </p>
    </div>
    <ul class="fs_link_box z_box">
      <li>
        <a
          href="#"
          class="fs_link"
        >QR코드</a>
      </li>
    </ul>
  </div>
</template>
