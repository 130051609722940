<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>스탬프 찾기</ion-title>
        <ion-buttons slot="end">
          <ion-button @click.prevent.stop="onDismissModal()">닫기</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="loding_wrap">
      <div class="qr-center-stream">
        <qr-stream @decode="onDecode" @init="onInit" class="qr">
          <div style="color: red" class="frame"></div>
        </qr-stream>
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped lang="scss">
@import "./QRScanner.scss";
</style>
<script>
import {
  IonContent,
  IonPage,
  modalController,
  alertController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { QrStream } from "vue3-qr-reader";
import { Analytics } from "../common/analytics";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    QrStream,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
  },
  setup() {
    const screenName = "MODAL-RADAR_QRCODE";
    const analytics = new Analytics();
    const state = reactive({
      data: {},
    });

    const presentAlert = async (message, callback = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onDecode = async (data) => {
      state.data = data;
      console.log(data);
      console.log(data.indexOf("stamppop"));
      if (data.indexOf("stamppop") != -1) {
        const temp = data.split("/");
        const id = temp[temp.length - 1];
        console.log(id);
        modalController.dismiss({
          id: id,
        });
      }
    };

    const onInit = async (promise) => {
      try {
        await promise;
      } catch (error) {
        let message = "";
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = "User denied the request for Camera.";
            break;
          case error.POSITION_UNAVAILABLE:
            message = "Camera information is unavailable.";
            break;
          case error.TIMEOUT:
            message = "The request to get user Camera timed out.";
            break;
          case error.UNKNOWN_ERROR:
            message = "An unknown error occurred.";
            break;
        }

        presentAlert(message /*'카메라를 허용해 주세요.'*/, async () => {
          modalController.dismiss();
        });
      } finally {
        console.log("a");
      }
    };

    const onDismissModal = async () => {
      modalController.dismiss();
    };

    onMounted(() => {
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { state, onDecode, onInit, onDismissModal };
  },
});
</script>

<style scoped>
</style>